body,
html {
  margin: 0;
  font-family: '微软雅黑', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 750px;
}

/*整个滚动条*/
::-webkit-scrollbar {
  width: 9px;
  background-color: #fff;
}

/*水平的整个滚动条*/
::-webkit-scrollbar:horizontal {
  height: 9px;
  background-color: #52565E;
}

/*滚动条轨道*/
::-webkit-scrollbar-track {
  background-color: #fff;
}

/*竖直的滑块*/
::-webkit-scrollbar-thumb {
  background-color: #52565E;
  border-radius: 5px;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0
}

body {
  font: 12px"宋体", "Arial Narrow", HELVETICA;
  background: #fff;
  -webkit-text-size-adjust: 100%;
}

a {
  color: #2d374b;
  text-decoration: none
}

a:hover {
  color: #cd0200;
  text-decoration: underline
}

em {
  font-style: normal
}

li {
  list-style: none
}

img {
  border: 0;
  vertical-align: middle
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

p {
  word-wrap: break-word
}
@primary-color: #FB9424;@bg-color: #F3F3F5;@link-color: #FB9424;@success-color: #37C879;@warning-color: #faad14;@error-color: #FD665D;@font-size-base: 14px;@heading-color: #252525;@text-color: #252525;@text-color-secondary: #71757D;@disabled-color: #DADAE0;@border-radius-base: 2px;@border-color-base: #CCD2E0;